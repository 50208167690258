<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="ma-0 py-0 mt-5">
        <v-btn color="#4caf50" @click="BackToSupplier">
          <v-icon left>mdi-arrow-left</v-icon>
          {{ $vuetify.lang.t("$vuetify.supplier") }}
        </v-btn>
      </v-col>
    </v-row>
    <PageNavigation :items="items" />
    <v-row>
      <v-col cols="12">
        <base-material-card
          :title="$vuetify.lang.t('$vuetify.receipt') + titleName"
          icon="mdi-file-document"
        >
          <v-sheet>
            <v-toolbar-items class="d-flex align-center">
              <v-row>
                <v-col lg="6">
                  <v-radio-group v-model="radioOption" row>
                    <v-radio
                      v-for="item in radioItems"
                      :key="item.title"
                      :label="item.title"
                      :value="item.title"
                    />
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-spacer />
              <v-btn color="green" elevation="2" @click="handleDialog"
                ><v-icon left>mdi-account-plus</v-icon
                >{{ $vuetify.lang.t("$vuetify.add") }}</v-btn
              >
            </v-toolbar-items>
            <tableImg
              :head="headers"
              :data="datas"
              :search="search"
              v-on:edititem="handleDialogEdit"
            />
          </v-sheet>
        </base-material-card>

        <payCRUD
          :dialog.sync="dialog"
          @handledata="handleSupplierPay"
          :myObj="obj"
          :currency="currency"
          v-on:selectPurchaseOrderList="handleSelect"
        />

        <selectPurchaseOrderList 
          :dialogPurchaseOrderList.sync="dialogPurchaseOrderList"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { supplierInfo } from "@/api/basicdata";
import { listCurrency } from "@/api/system";
export default {
  name: "supplierPay",
  components: {
    PageNavigation: () => import("@/components/pageNavigation"),
    tableImg: () => import("@/components/tableImg"),
    payCRUD: () => import("@/components/purchase/supplierPayCRUD"),
    selectPurchaseOrderList: () => import("@/components/purchase/selectPurchaseOrderList")
  },
  data() {
    return {
      dialog: false,
      items: [
        { text: this.$vuetify.lang.t("$vuetify.home") },
        { text: this.$vuetify.lang.t("$vuetify.purchase") },
        { text: this.$vuetify.lang.t("$vuetify.supplier") },
        { text: this.$vuetify.lang.t("$vuetify.receipt") },
      ],
      radioOption: "All",
      radioItems: [
        { title: this.$vuetify.lang.t("$vuetify.categoryListAll") },
        { title: this.$vuetify.lang.t("$vuetify.Unpaid1") },
        { title: this.$vuetify.lang.t("$vuetify.paid") },
        { title: this.$vuetify.lang.t("$vuetify.canceled") },
      ],
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.applicant"),
        },
        {
          text: this.$vuetify.lang.t("$vuetify.TotalAmount"),
        },
        {
          text: this.$vuetify.lang.t("$vuetify.currency"),
        },
        {
          text: this.$vuetify.lang.t("$vuetify.currency"),
        },
        {
          text: this.$vuetify.lang.t("$vuetify.AccountPeriod"),
        },
        {
          text: this.$vuetify.lang.t("$vuetify.applyDate"),
        },
        {
          text: this.$vuetify.lang.t("$vuetify.note"),
        },
        {
          text: this.$vuetify.lang.t("$vuetify.actionState"),
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],
      search: "",
      datas: [],
      titleName: "",
      searchForm: {
        supplier_id: "",
        order_id: "",
        status: -100,
        skip: 0,
        limit: 10,
      },
      obj: {
        id: "",
        supplier_id: "",
        bank_account: "",
        bank_name: "",
        orders: "",
        money: 0,
        currency: "RMB",
        check_month: "",
        note: "",
      },
      currency: [],
      dialogPurchaseOrderList: false
    };
  },
  methods: {
    BackToSupplier() {
      this.$router.push("/purchase/supplier");
    },
    getInfo() {
      supplierInfo(this.searchForm.supplier_id).then((res) => {
        this.titleName = ` (` + this.$vuetify.lang.t("$vuetify.company") + `: ${res.item.company || res.item.company_en})`;
      });
    },
    handleSelect(){
        this.dialogPurchaseOrderList = true
    },
    getData() {

      this.currency = [];

      listCurrency()
        .then((data) => {
          data.items.forEach((p) => this.currency.push(p.name))
        })
        .catch((err) => console.log(err));
    },
    handleDialog() {
      this.dialog = true;
      this.obj = {
        id: "",
        supplier_id: "",
        bank_account: "",
        bank_name: "",
        orders: "",
        money: 0,
        currency: "RMB",
        check_month: "",
        note: "",
      };
    },
    handleSupplierPay() {
      this.dialog = false;
    },
    handleDialogEdit(obj) {
      this.dialog = true;
      this.obj = obj;
    },
    
  },
  mounted() {
    this.searchForm.supplier_id = this.$route.query.id;
    this.getInfo();
    this.getData();
  },
};
</script>
